import React, { useMemo } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { PrivateRoute } from 'Utils/private-route'
import { NotificationContainer } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import { ConfigProvider } from 'antd'
import { io } from 'socket.io-client'
import { setSocket } from 'Redux/Chat/chat.actions'
import { useDispatch } from 'react-redux'
import { Toaster } from 'react-hot-toast'
const Login = React.lazy(() => import('./Containers/Login'))
const ChatWindow = React.lazy(() => import('./Containers/NewChat/ChatScreen'))
const ChatNav = React.lazy(() => import('./Containers/NewChat/ChatList'))
const loginCode = React.lazy(() => import('./Containers/LoginCode'))

function App() {
  const dispatch = useDispatch()
  const socket = useMemo(() => io('https://api.staging.trillium.health'), [])
  // const socket = useMemo(() => io('http://localhost:8080'), [])

  dispatch(setSocket(socket))
  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            headerHeightSM: 10,
            headerFontSizeSM: 12,
          },
        },
        token: {
          borderRadius: 15,
          colorPrimary: '#139696',
          colorLink: '#139696',
          colorInfo: '#139696',
          colorSuccess: '#1ACE7F',
          colorError: '#E15A3C',
          colorWarning: '#FFBF43',
          colorTextBase: '#3C4257',
        },
      }}
    >
      <BrowserRouter>
        <React.Suspense fallback={''}>
          <Switch>
            <PrivateRoute authed={true} path="/chat" component={ChatWindow} />
            {/* <PrivateRoute authed={true} path="/home" component={ChatNav} /> */}
            <Route path="/login" component={Login} />
            <Route path="/" component={loginCode} />
            <Redirect from="/" to="/login" />
          </Switch>
          <NotificationContainer />
          <Toaster position="top-right" reverseOrder={false} />
        </React.Suspense>
      </BrowserRouter>
    </ConfigProvider>
  )
}

export default App
